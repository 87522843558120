import { Component } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";
import { SnackbarComponent } from "../snackbar.component";

@Component({
  selector: "app-success-message",
  imports: [TranslateModule, MatIconModule],
  templateUrl: "./success-message.component.html",
  styleUrl: "./success-message.component.less",
})
export class SuccessMessageComponent extends SnackbarComponent<string> {
  public constructor() {
    super();
  }
}
