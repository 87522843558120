import { CommonModule } from "@angular/common";
import { Component, OnInit, inject } from "@angular/core";
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { BookmarkService } from "src/app/secure/bookmarks/bookmarks.service";
import { Bookmark, BookmarkGroup } from "src/interfaces/session/session-bookmark";
import { ApplicationService } from "src/services/application.service";
import { SessionService } from "src/services/session.service";
import { TemplateTextComponent } from "../../../prefixes/templates/template-text/template-text.component";
import { DialogTitle } from "../../template/dialog-template.component";
import { DialogComponent } from "../dialog.component";

export interface SelectBookmarkDialogData {
  title: DialogTitle;
}

@Component({
  selector: "app-select-bookmark",
  templateUrl: "./select-bookmark.component.html",
  styleUrl: "./select-bookmark.component.less",
  imports: [MatIconModule, FormsModule, ReactiveFormsModule, CommonModule, TranslateModule, TemplateTextComponent, MatButtonModule],
})
export class SelectBookmarkComponent extends DialogComponent<SelectBookmarkDialogData> implements OnInit {
  private application: ApplicationService;
  private session: SessionService;
  private translate: TranslateService;
  public bookmarks: BookmarkService;

  public input: FormControl<string | null>;
  public creating: boolean;
  public loading: boolean;

  public constructor() {
    super();
    this.application = inject(ApplicationService);
    this.session = inject(SessionService);
    this.bookmarks = inject(BookmarkService);
    this.translate = inject(TranslateService);

    this.loading = false;
    this.creating = false;
    this.input = new FormControl<string | null>(this.translate.instant("DIALOG.BOOKMARKS.CREATE.NEW_GROUP_NAME"), Validators.required);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.bookmarks.initialize();
  }

  public async makeBookmark(groupName: string | null): Promise<void> {
    try {
      if (!groupName) throw new Error("No groupname");
      const name = this.translate.instant(groupName);
      this.loading = true;
      const role = this.session.role.value;
      const other = this.session.workspace.value;

      if (!role || !other) throw new Error("Faulty data");

      const bookMarkGroup = this.bookmarks.getBookmarkGroup(name);
      const bookmark = this.createBookmark(bookMarkGroup, this.application.title.value || "-", role.displayname, other.displayname);

      bookMarkGroup.bookmarks.push(bookmark);

      this.bookmarks.removeBookmarkIfIsNotUniqueCheck();

      await this.bookmarks.saveBookmarks();

      this.close();
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  }

  // probably redundant
  public createBookmark(group: BookmarkGroup, title: string, role: string, workspace: string): Bookmark {
    return {
      Description: title,
      ID: "0",
      group: group.Description,
      grouporder: "0",
      // link: window.location.href.split("/")[window.location.href.split("/").length - 1],
      link: `${window.location.pathname}?groupID=${group.ID}`,
      role,
      workspace,
    };
  }

  public isInBookmarkGroup(groupName: string): boolean {
    const name = this.translate.instant(groupName);
    const group = this.bookmarks.bookmarkGroup$.value.get(name);
    const bookmarkExists = group?.bookmarks.some((bookmark) => bookmark.link === `${window.location.pathname}?groupID=${group.ID}`) || false;
    return bookmarkExists;
  }
}
