import { Component } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";
import { SnackbarComponent } from "../snackbar.component";

@Component({
  selector: "app-error-message",
  imports: [TranslateModule, MatIconModule],
  templateUrl: "./error-message.component.html",
  styleUrl: "./error-message.component.less",
})
export class ErrorMessageComponent extends SnackbarComponent<string> {
  public constructor() {
    super();
  }
}
