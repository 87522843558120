import { Component } from "@angular/core";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialogModule } from "@angular/material/dialog";
import { TranslateModule } from "@ngx-translate/core";
import { DialogTitle } from "../../template/dialog-template.component";
import { DialogComponent } from "../dialog.component";

export interface LimitedPrintDialogData {
  title: DialogTitle;
}

@Component({
  selector: "app-limited-print-dialog",
  imports: [TranslateModule, MatCheckboxModule, ReactiveFormsModule, MatDialogModule],
  templateUrl: "./limited-print-dialog.component.html",
  styleUrl: "./limited-print-dialog.component.less",
})
export class LimitedPrintDialogComponent extends DialogComponent<LimitedPrintDialogData> {
  public printNotFilledIn: FormControl<boolean | null>;

  public constructor() {
    super();
    this.printNotFilledIn = new FormControl<boolean>(false);
  }
}
