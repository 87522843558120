import { Component, Input, OnInit } from "@angular/core";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { DefaultComponent } from "src/app/default.component";
import { PrefixTemplate } from "../../PrefixTemplate";
import { PrefixValidator } from "../../PrefixValidator";
import { MaxLength } from "../../types/MaxLength";
import { MinLength } from "../../types/MinLength";
import { Regex } from "../../types/Regex";
import { TemplateHTMLComponent } from "../template-html/template-html.component";

export type TextAlign = "start" | "center" | "end";

@Component({
  imports: [ReactiveFormsModule, TemplateHTMLComponent],
  selector: "app-template-text",
  templateUrl: "./template-text.component.html",
  styleUrl: "./template-text.component.less",
})
export class TemplateTextComponent extends DefaultComponent implements PrefixTemplate<string>, OnInit {
  @Input({ required: true })
  public control: FormControl<string | null> | null;

  @Input()
  public value: string | null;

  @Input()
  public minlength: MinLength;

  @Input()
  public maxlength: MaxLength;

  @Input()
  public regex: Regex;

  @Input()
  public required: boolean;

  @Input()
  public disabled: boolean;

  @Input()
  public align: TextAlign;

  @Input()
  public characters: string | null;

  @Input()
  public counter: boolean;

  @Input()
  public placeholder: string;

  @Input()
  public label: string | null;

  public focused: boolean;

  public constructor() {
    super();
    this.control = null;
    this.value = null;
    this.minlength = null;
    this.maxlength = null;
    this.regex = null;
    this.required = false;
    this.disabled = false;
    this.align = "start";
    this.characters = null;
    this.counter = false;
    this.placeholder = "";
    this.focused = false;
    this.label = null;
  }

  public ngOnInit(): void {
    const control = this.control;
    if (control) {
      this.addValidators(control);
    } else {
      throw new Error("Undefined control");
    }
  }

  public onBeforeInput(event: InputEvent): void {
    const input = event.data?.split("") ?? [];
    const invalid = !!input.filter((str) => this.characters && !this.characters.includes(str)).length;
    if (invalid) event.preventDefault();
  }

  /**
   * Set counter boolean
   */
  public switchFocus(): void {
    this.focused = !this.focused;
  }

  private addValidators(control: FormControl<string | null>): void {
    if (this.minlength) control.addValidators([PrefixValidator.minlength(this.minlength)]);
    if (this.maxlength) control.addValidators([PrefixValidator.maxlength(this.maxlength)]);
    if (this.regex) control.addValidators([PrefixValidator.regex(this.regex)]);
    if (this.required) control.addValidators([PrefixValidator.required()]);

    control.updateValueAndValidity();
  }
}
